<template>
  <div
    :class='loginBackgroundCssClasses'
    :style='loginBackgroundStyle'>
    <login-form
      :single-session='singleSession'
      
      :logo-url='logoUrl'
      :hide-top-logo='hideTopLogo'
      :logo-css-classes='logoCssClass'
      :logo-custom-css='logoCustomCss'
      :logo-height='logoHeight'

      :email-placeholder='idPlaceholderText'
      :email-field-default-value='emailFieldDefaultValue'
      :hide-email-field='hideEmailField'

      :hide-password-field='hidePasswordField'
      :password-field-default-value='passwordFieldDefaultValue'
      :custom-password-field-input-type='passwordFieldType'
      :password-placeholder='passwordPlaceholderText'
      :password-field-name='passwordFieldName'
      
      :hide-field-entry-confirmation='hideFieldEntryConfirmation'
      :custom-email-field-entry-confirmation='emailFieldEntryConfirmation'
      :custom-password-field-entry-confirmation='passwordFieldEntryConfirmation'
      
      :login-button-text='buttonText'
      :button-css-class-override='buttonCssClassOverride'
      :button-style-override='buttonStyleOverride'
      :include-signup-link='includeSignupLink'

      :check-paid-status='checkPaidStatus'

      :class='loginBoxCssClasses'
      :style='loginBoxCssStyles'>
      <div v-if='topHtmlContent' v-html='topHtmlContent'></div>
      <div slot='bottom-content' class='w-full' v-html='bottomHtmlContent'></div>
    </login-form>
    <div v-if='otherLoginPageContent' 
      v-html='otherLoginPageContent' 
      :class='otherLoginContentClasses'
      :style='otherLoginContentCssStyles'></div>
  </div>

</template>

<script>
import LoginForm from '@/components/LoginForm.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  beforeRouteLeave(to, from, next) {
    if (!to.query.on_mobile_app) {
      this.$emit('update:layout', 'default-layout')
    }
    next()
  },
  name: 'Login',
  components: {
    LoginForm,
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'showingEventLoginPageConfigurations',
    ]),
    singleSession () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.singleSession : false
    },
    logoUrl () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoUrl : ''
    },
    logoHeight () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoHeight : ''
    },
    logoCssClass () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCssClass : ''
    },
    logoCustomCss () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.logoCustomCss : ''
    },
    hideTopLogo () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hideTopLogo : false
    },
    hideEmailField () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hideEmailField : false
    },
    idPlaceholderText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.emailPlaceholderText : ''
    },
    emailFieldDefaultValue () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.emailFieldDefaultValue : ''
    },
    hidePasswordField () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hidePasswordField : false
    },
    passwordFieldName () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordFieldName : 'password'
    },
    passwordFieldDefaultValue () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordFieldDefaultValue : ''
    },
    passwordPlaceholderText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.passwordPlaceholderText : ''
    },
    passwordFieldType () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.customPasswordFieldInputType : 'password'
    },
    hideFieldEntryConfirmation () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.hideFieldEntryConfirmation : false
    },
    emailFieldEntryConfirmation () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.customEmailFieldEntryConfirmation : ''
    },
    passwordFieldEntryConfirmation () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.customPasswordFieldEntryConfirmation : ''
    },
    buttonText () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginButtonText : ''
    },
    buttonCssClassOverride () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.buttonCssClassOverride : ''
    },
    buttonStyleOverride () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.buttonStyleOverride : ''
    },
    includeSignupLink () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.includeSignupLink : false
    },
    topHtmlContent () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.topHtmlContent : ''
    },
    bottomHtmlContent () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.bottomHtmlContent : ''
    },
    loginBoxAlignment () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBoxAlignment : 'center'
    },
    checkPaidStatus () {
      return (this.showingEventLoginPageConfigurations && this.showingEventLoginPageConfigurations.checkPaidStatus) ? this.showingEventLoginPageConfigurations.checkPaidStatus : false
    },
    loginBoxCssClasses () {
      let mobileClasses = 'w-11/12 flex flex-col items-center justify-center py-12 px-6'
      let fullscreenClasses = 'lg:w-1/2 lg:max-w-2xl lg:flex-shrink-0 lg:flex-grow-0 lg:py-24 lg:px-12 lg:border-0 lg:rounded-xl'
      let overrideClasses = (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBoxCssClasses : ''
      return `standard-transition ${mobileClasses} ${fullscreenClasses} ${overrideClasses}`
    },
    loginBoxCssStyles () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBoxCssStyles : ''
    },
    loginBackgroundImage () {
      return (this.showingEventLoginPageConfigurations && this.showingEventLoginPageConfigurations.backgroundImageUrl) ? `background-image: url('${this.showingEventLoginPageConfigurations.backgroundImageUrl}');` : ''
    },
    loginBackgroundCssClasses () {
      const baseClasses     = 'flex min-h-screen relative bg-top-left bg-no-repeat bg-cover'
      const mobileClasses   = 'flex flex-col justify-center items-center'
      let boxAlginmentClasses = ''
      if (this.loginBoxAlignment === 'center') {
        boxAlginmentClasses = 'lg:flex-row lg:justify-center'
      } else if (this.loginBoxAlignment === 'left') {
        boxAlginmentClasses = 'lg:flex-row lg:justify-start'
      } else if (this.loginBoxAlignment === 'right') {
        boxAlginmentClasses = 'lg:flex-row-reverse lg:justify-end'
      }
      const desktopClasses  = `${boxAlginmentClasses} lg:items-center`
      const overrideClasses = (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBackgroundClasses : ''
      return `${baseClasses} ${mobileClasses} ${desktopClasses} ${overrideClasses}`
    },
    loginBackgroundStyle () {
      let configStyles = (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.loginBackgroundStyle : ''
      return `${this.loginBackgroundImage} ${configStyles}`
    },
    otherLoginPageContent () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.otherLoginPageContent : ''
    },
    otherLoginContentClasses () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.otherLoginContentClasses : ''
    },
    otherLoginContentCssStyles () {
      return (this.showingEventLoginPageConfigurations) ? this.showingEventLoginPageConfigurations.otherLoginContentCssStyles : ''
    },
    redirectRouteObject () {
      const redirectRoute = this.$route.query.redirect_route ? this.$route.query.redirect_route : 'Home'
      const redirectRouteSponsorId = this.$route.query.sponsor_id ? this.$route.query.sponsor_id : ''

      let routeObject = { name: redirectRoute }

      if (redirectRoute === 'Sponsor' && redirectRouteSponsorId) {
        routeObject['query'] = {sponsor_id: redirectRouteSponsorId }
      }
      return routeObject
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.$router.push(this.redirectRouteObject).catch(() => {})
      }
    }).catch(() => {
      console.log('need to login')
    })
    /* eslint-disable */
    try { login.postMessage('401') } catch (err) { 
      // do nothing
    }
    /* eslint-enable */
  },
}
</script>

